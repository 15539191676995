.outerDateSelection {
  display: flex;
  margin-left: 10px;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  /* background-color: #EBEBEB; */
  width: fit-content;
  padding: 8px;
  border-radius: 20px;

  @media (--viewportMobile) {
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
  }
}
.outerDateSelectionInput {
  border: 0;
  border-bottom: 1px solid #b5b5b5;
  border-radius: 0;
  width: fit-content;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #0b150a;
  padding: 10px 5px;
  background-color: transparent;
  &::placeholder {
    color: #b5b5b5;
  }
  &:focus {
    border-color: #0058fa;
    box-shadow: none;
    background-color: transparent;
  }
  &:hover {
    background-color: transparent;
  }
  @media screen and (--viewportMobile) {
    width: 100%;
  }
}
.outerDateSelectionSelect {
  height: auto;
  @media screen and (--viewportMobile) {
    width: 100%;
  }
}
.outerDateSelectionSelect[class*='ant-select'] [class='ant-select-selector'] {
  border: 0;
  border-bottom: 1px solid #b5b5b5;
  border-radius: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #0b150a;
  padding: 10px 5px;
  width: 120px;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  height: 45px;
  @media screen and (--viewportMobile) {
    width: 100%;
  }
}
/* .outerDateSelectionSelect:hover{
    border-bottom: 1px solid #b5b5b5;
} */
.outerDateSelectionSelect[class*='ant-select'][class*='ant-select-focused']
  [class='ant-select-selector'] {
  outline: none !important;
  box-shadow: none !important;
}
.outerDateSelectionDatePicker {
  border: 0;
  border-bottom: 1px solid #b5b5b5;
  border-radius: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #0b150a;
  padding: 10px 5px;
  background-color: transparent;
  @media screen and (--viewportMobile) {
    width: 100%;
  }
}
.outerDateSelectionDatePicker:focus-within,
.outerDateSelectionDatePicker:hover {
  box-shadow: none;
  background-color: transparent;
}
.outerDateSelectionDatePickerSelector {
  display: block;
}
.datePickerSelectorButtonWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.datePickerSelectorButtonWrap .datePickerSelectorButton {
  background: #0058fa;
  border: 1px solid transparent;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  padding: 0 18px;
  width: auto;
  line-height: 34px;
  border-radius: 4px;
  @media (--viewportMobile) {
    padding: 10px 14px;
    font-size: 14px;
    line-height: 15px;
    border-radius: 6px;
  }
}
.outerDateSelectionDatePickerSelector [class*='ant-picker-footer'] {
  display: none;
}

.searchIcon {
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
  background-color: #0058fa;
  border-radius: 20px;
  padding: 7px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 7px;
}

.outerDateSelectionWrapper {
  display: flex;

  @media (--viewportMobile) {
    flex-direction: column;
    width: 100%;
    gap: 5px;
  }
}
.outerDate {
  @media (--viewportMobile) {
    width: 100%;
  }
}
